import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Button, Col, Nav, NavItem, Row, Spinner, TabContent, TabPane } from 'reactstrap';
import { toastr } from 'react-redux-toastr';
import { CustomInput } from '../../../components/Input/CustomInput';
import { apiRequest, TOAST_IMPORTANT_TIME_OUT_MS } from '../../../utils';
import Tooltip from '../../../components/Tooltip';
import IconsProductList from './IconsProductList';

const ProductCategorySchema = yup
  .object({
    title: yup.object({
      en: yup.string().required('Main title is required.'),
      de: yup.string().required('Sub title is required.'),
    }),
    header: yup.object({
      en: yup.string().required('Main title is required.'),
      de: yup.string().required('Sub title is required.'),
    }),
    metadescription: yup.object({
      en: yup.string().required('English header is required.'),
      de: yup.string().required('German header is required.'),
    }),
    seo: yup.object({
      en: yup.string().required('English header is required.'),
      de: yup.string().required('German header is required.'),
    }),
    imageUrl: yup.string().required('This field is required.'),
    _id: yup.string().nullable(),
    displayRank: yup.number(),
  })
  .defined();
type ProductCategory = yup.InferType<typeof ProductCategorySchema>;

const CreateProductCategoryForm = ({ initialValues, onCreation, isViewOnly = false }: CreateProductCategoryFormProps) => {
  const [tab, setTab] = useState<'create' | 'update'>('create');
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState<ProductCategory>({
    title: {
      en: '',
      de: '',
    },
    header: {
      en: '',
      de: '',
    },
    metadescription: {
      en: '',
      de: '',
    },
    seo: {
      en: '',
      de: '',
    },
    imageUrl: '',
    _id: '',
    displayRank: 0,
  });

  useEffect(() => {
    if (initialValues) {
      setValues(initialValues);
    }
  }, [initialValues]);

  const enableLoading = () => {
    setLoading(true);
  };
  const disableLoading = () => {
    setLoading(false);
  };

  const onSubmit = async (values: ProductCategory, action: any) => {
    enableLoading();
    let response;
    const valuesCopy = { ...values };
    delete valuesCopy._id;

    if (initialValues && initialValues._id) {
      response = await updateRequest(initialValues._id, JSON.stringify(valuesCopy));
    } else {
      response = await createRequest(JSON.stringify(valuesCopy));
    }

    const { message, ...data } = response.data;

    if (message) {
      if (Array.isArray(message)) {
        message.forEach((msg) => toastr.error('Error', msg, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS }));
      } else {
        toastr.error('Error', message, { timeOut: TOAST_IMPORTANT_TIME_OUT_MS });
      }
      action.setSubmitting(false);
      return disableLoading();
    }

    await onCreation(!initialValues || !initialValues._id ? data._id : null);

    toastr.success('Success', `Category ${initialValues && initialValues._id ? 'updated' : 'created'}`);
    return disableLoading();
  };

  const updateRequest = async (_id: string, data: string) => await apiRequest(`/product/category/${_id}`, { method: 'PUT', data });
  const createRequest = async (data: string) => await apiRequest('/product/category', { method: 'POST', data });

  return (
    <TabContent activeTab={tab} className="h-100 assignment-list-tab-content">
      <Nav tabs>
        <NavItem onClick={() => setTab('create')} className={`nav-link ${tab === 'create' ? 'active' : ''}`}>
          Create
        </NavItem>
        <NavItem onClick={() => setTab('update')} className={`nav-link ${tab === 'update' ? 'active' : ''}`}>
          Update
        </NavItem>
      </Nav>
      <TabPane tabId="create" className="h-100">
        <Row className="justify-content-center">
          <Col md={8}>
            <Formik initialValues={values} validationSchema={ProductCategorySchema} onSubmit={onSubmit}>
              {(formik) => (
                <>
                  <FormData formik={formik} isViewOnly={isViewOnly} />
                  {!isViewOnly && (
                    <Button
                      className="btn-md"
                      disabled={loading}
                      onClick={() =>
                        onSubmit(formik.values, {
                          setSubmitting: formik.setSubmitting,
                        })
                      }
                    >
                      Create Product Category {loading && <Spinner size="sm" color="secondary" />}
                    </Button>
                  )}
                </>
              )}
            </Formik>
          </Col>
        </Row>
      </TabPane>
      <TabPane tabId="update" className="h-100">
        <Row className="justify-content-center">
          <Col md={8}>
            <Formik enableReinitialize initialValues={initialValues ?? values} validationSchema={ProductCategorySchema} onSubmit={onSubmit}>
              {(formik) => (
                <>
                  <FormData formik={formik} isViewOnly={isViewOnly} />
                  {!isViewOnly && (
                    <Button
                      className="btn-md"
                      disabled={loading}
                      onClick={() =>
                        onSubmit(formik.values, {
                          setSubmitting: formik.setSubmitting,
                        })
                      }
                    >
                      Update Product Category {loading && <Spinner size="sm" color="secondary" />}
                    </Button>
                  )}
                </>
              )}
            </Formik>
          </Col>
        </Row>
      </TabPane>
    </TabContent>
  );
};

const FormData = ({ formik, isViewOnly }: { formik: any; isViewOnly: boolean }) => (
  <div className="d-flex flex-column gap-3 my-3">
    <Row>
      <Col>
        <CustomInput
          label="Title En"
          inputProps={{
            id: 'title.en',
            name: 'title.en',
            type: 'text',
            value: formik.values.title?.en,
            onChange: formik.handleChange,
            disabled: isViewOnly,
          }}
          addon={
            <Tooltip placement="bottom" target="product-category-title-example">
              <img src="/images/product/category/product-category-title-example.png" alt="Example" width={400} height={250} />
            </Tooltip>
          }
          error={formik.touched.title && formik.errors.title ? formik.errors.title : ''}
        />
      </Col>
    </Row>
    <Row>
      <Col>
        <CustomInput
          label="Title De"
          inputProps={{
            id: 'title.de',
            name: 'title.de',
            type: 'text',
            value: formik.values.title?.de,
            onChange: formik.handleChange,
            disabled: isViewOnly,
          }}
          addon={
            <Tooltip placement="bottom" target="product-category-title-example">
              <img src="/images/product/category/product-category-title-example.png" alt="Example" width={400} height={250} />
            </Tooltip>
          }
          error={formik.touched.title && formik.errors.title ? formik.errors.title : ''}
        />
      </Col>
    </Row>
    <Row>
      <CustomInput
        label="Header En"
        inputProps={{ id: 'header.en', name: 'header.en', type: 'text', value: formik.values.header?.en, onChange: formik.handleChange, disabled: isViewOnly }}
        addon={
          <Tooltip placement="bottom" target="product-category-header-example">
            <img src="/images/product/category/product-category-header-example.png" alt="Example" width={400} height={250} />
          </Tooltip>
        }
        error={formik.touched.header && formik.errors.header ? formik.errors.header : ''}
      />
    </Row>
    <Row>
      <CustomInput
        label="Header De"
        inputProps={{ id: 'header.de', name: 'header.de', type: 'text', value: formik.values.header?.de, onChange: formik.handleChange, disabled: isViewOnly }}
        addon={
          <Tooltip placement="bottom" target="product-category-header-example">
            <img src="/images/product/category/product-category-header-example.png" alt="Example" width={400} height={250} />
          </Tooltip>
        }
        error={formik.touched.header && formik.errors.header ? formik.errors.header : ''}
      />
    </Row>
    <Row>
      <Col>
        <CustomInput
          label="Meta Description De"
          inputProps={{
            id: 'metadescription.de',
            name: 'metadescription.de',
            type: 'text',
            value: formik.values.metadescription?.de,
            onChange: formik.handleChange,
            disabled: isViewOnly,
          }}
          addon={
            <Tooltip placement="bottom" target="product-category-title-example">
              <img src="/images/product/category/product-category-title-example.png" alt="Example" width={400} height={250} />
            </Tooltip>
          }
          error={formik.touched.metadescription?.de && formik.errors.metadescription?.de ? formik.errors.metadescription?.de : ''}
        />
      </Col>
    </Row>
    <Row>
      <Col>
        <CustomInput
          label="Meta Description En"
          inputProps={{
            id: 'metadescription.en',
            name: 'metadescription.en',
            type: 'text',
            value: formik.values.metadescription?.en,
            onChange: formik.handleChange,
            disabled: isViewOnly,
          }}
          addon={
            <Tooltip placement="bottom" target="product-category-title-example">
              <img src="/images/product/category/product-category-title-example.png" alt="Example" width={400} height={250} />
            </Tooltip>
          }
          error={formik.touched.metadescription?.en && formik.errors.metadescription?.en ? formik.errors.metadescription?.en : ''}
        />
      </Col>
    </Row>
    <Row>
      <Col>
        <CustomInput
          label="Seo De"
          inputProps={{
            id: 'seo.de',
            name: 'seo.de',
            type: 'text',
            value: formik.values.seo?.de,
            onChange: formik.handleChange,
            disabled: isViewOnly,
          }}
          addon={
            <Tooltip placement="bottom" target="product-category-title-example">
              <img src="/images/product/category/product-category-title-example.png" alt="Example" width={400} height={250} />
            </Tooltip>
          }
          error={formik.touched.seo?.de && formik.errors.seo?.de ? formik.errors.seo?.de : ''}
        />
      </Col>
    </Row>
    <Row>
      <Col>
        <CustomInput
          label="Seo En"
          inputProps={{
            id: 'seo.en',
            name: 'seo.en',
            type: 'text',
            value: formik.values.seo?.en,
            onChange: formik.handleChange,
            disabled: isViewOnly,
          }}
          addon={
            <Tooltip placement="bottom" target="product-category-title-example">
              <img src="/images/product/category/product-category-title-example.png" alt="Example" width={400} height={250} />
            </Tooltip>
          }
          error={formik.touched.seo?.en && formik.errors.seo?.en ? formik.errors.seo?.en : ''}
        />
      </Col>
    </Row>
    <Row>
      <IconsProductList setValues={formik.setValues} imageUrl={formik.values.imageUrl} />
    </Row>
    <Row>
      <CustomInput
        label="Image URL"
        inputProps={{
          id: 'imageUrl',
          name: 'imageUrl',
          type: 'url',
          value: formik.values.imageUrl,
          onChange: formik.handleChange,
          disabled: isViewOnly,
        }}
        addon={
          <Tooltip placement="bottom" target="product-category-image-url-example">
            <img src="/images/product/category/product-category-image-url-example.png" alt="Example" width={400} height={250} />
          </Tooltip>
        }
        error={formik.touched.imageUrl && formik.errors.imageUrl ? formik.errors.imageUrl : ''}
      />
    </Row>
    <Row>
      <CustomInput
        label="Display Rank"
        inputProps={{
          id: 'displayRank',
          name: 'displayRank',
          type: 'number',
          value: formik.values.displayRank,
          onChange: formik.handleChange,
          disabled: isViewOnly,
        }}
        error={formik.touched.displayRank && formik.errors.displayRank ? formik.errors.displayRank : null}
      />
    </Row>
  </div>
);

type CreateProductCategoryFormProps = { isViewOnly?: boolean; initialValues?: ProductCategory; onCreation: (id: string) => Promise<void> };

export { CreateProductCategoryForm };
